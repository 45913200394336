<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-25">
            <form-date
              v-model="filters.from"
              name="dateFrom"
              label="Start Date From"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <form-date
              v-model="filters.to"
              name="dateTo"
              label="Start Date To"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <AgencySelect v-model="filters.agency_id" />
          </div>
          <div class="md-layout-item md-size-25">
            <CountriesAutocomplete
              v-model="filters.country_id"
              label="Nationality"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @changed="getData"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import {
  CountriesAutocomplete,
  AgencySelect,
} from '@/components/Inputs/selects';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormDate,
    CountriesAutocomplete,
    AgencySelect,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'agency.name',
          mask: 'agency',
          sortable: true,
        },
        {
          title: 'student.student_number',
          mask: 'student number',
          sortable: true,
        },
        {
          title: 'student.full_name',
          sort_value: 'student.first_name',
          mask: 'name',
          sortable: true,
        },
        {
          title: 'student.country.nationality',
          mask: 'nationality',
          sortable: true,
        },
        {
          title: 'student.email',
          mask: 'email',
          sortable: true,
        },
        {
          title: 'start_date',
          mask: 'start date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'course_weeks',
          mask: 'course weeks',
          sortable: true,
        },
        {
          title: 'holiday_weeks',
          mask: 'holiday weeks',
          sortable: true,
        },
      ],
      values: {},
      actions: [],
    },
    filters: {
      country_id: null,
      from: null,
      to: null,
      agency_id: null,
    },
    loading: false,
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.loading = true;

      this.request(
        'agencies/report',
        'get',
        {
          ...params,
          ...this.filters,
        },
        ({ data }) => {
          this.vTable.values = data;
        },
        () => {
          this.loading = false;
        },
      );
    },
    onFilter() {
      this.$refs.vtable.init();
    },
    onDownload() {
      this.loading = true;
      this.request(
        'agencies/report',
        'download',
        {
          ...this.filters,
          download: true,
        },
        ({ data }) => {
          const filename = `agencies_report_${moment().format(
            'YYYY_MM_DD',
          )}.xlsx`;
          downloadFile(data, filename);
        },
        () => {
          this.loading = false;
        },
      );
    },
  },
};
</script>

<style></style>
